exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-do-pobrania-tsx": () => import("./../../../src/pages/do-pobrania.tsx" /* webpackChunkName: "component---src-pages-do-pobrania-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kariera-asystent-ka-biura-tsx": () => import("./../../../src/pages/kariera/asystent-ka-biura.tsx" /* webpackChunkName: "component---src-pages-kariera-asystent-ka-biura-tsx" */),
  "component---src-pages-kariera-index-tsx": () => import("./../../../src/pages/kariera/index.tsx" /* webpackChunkName: "component---src-pages-kariera-index-tsx" */),
  "component---src-pages-kariera-ksiegowy-a-tsx": () => import("./../../../src/pages/kariera/ksiegowy-a.tsx" /* webpackChunkName: "component---src-pages-kariera-ksiegowy-a-tsx" */),
  "component---src-pages-kariera-specjalista-ka-do-spraw-kadr-tsx": () => import("./../../../src/pages/kariera/specjalista-ka-do-spraw-kadr.tsx" /* webpackChunkName: "component---src-pages-kariera-specjalista-ka-do-spraw-kadr-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-index-tsx": () => import("./../../../src/pages/oferta/index.tsx" /* webpackChunkName: "component---src-pages-oferta-index-tsx" */),
  "component---src-pages-oferta-kadry-i-place-tsx": () => import("./../../../src/pages/oferta/kadry-i-place.tsx" /* webpackChunkName: "component---src-pages-oferta-kadry-i-place-tsx" */),
  "component---src-pages-oferta-ksiegowosc-spolek-wroclaw-tsx": () => import("./../../../src/pages/oferta/ksiegowosc-spolek-wroclaw.tsx" /* webpackChunkName: "component---src-pages-oferta-ksiegowosc-spolek-wroclaw-tsx" */),
  "component---src-pages-oferta-ksiegowosc-wroclaw-tsx": () => import("./../../../src/pages/oferta/ksiegowosc-wroclaw.tsx" /* webpackChunkName: "component---src-pages-oferta-ksiegowosc-wroclaw-tsx" */),
  "component---src-pages-oferta-rachunkowosc-zarzadcza-i-controlling-tsx": () => import("./../../../src/pages/oferta/rachunkowosc-zarzadcza-i-controlling.tsx" /* webpackChunkName: "component---src-pages-oferta-rachunkowosc-zarzadcza-i-controlling-tsx" */),
  "component---src-pages-oferta-specjalna-tsx": () => import("./../../../src/pages/oferta-specjalna.tsx" /* webpackChunkName: "component---src-pages-oferta-specjalna-tsx" */),
  "component---src-pages-referencje-tsx": () => import("./../../../src/pages/referencje.tsx" /* webpackChunkName: "component---src-pages-referencje-tsx" */)
}

